import '../../stylesheets/web/web.scss'

document.addEventListener('DOMContentLoaded', function () {
  const filter = document.querySelector("#PagesFilter")
  filter?.addEventListener("keyup", (event) => {
    const val = event.target.value.toLowerCase()
    const els = document.querySelectorAll("#PagesTables tr")
    for(let el of els) {
      const tds = [...el.querySelectorAll("[search=true]")]
      const notContain = tds?.some(t => { return t.innerText.toLowerCase().includes(val)})
      if(notContain) {
        el.classList.remove('d-none')
      } else {
        el.classList.add('d-none')
      }
    }
  })
})