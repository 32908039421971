// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//Some magic to make jquery work
import Rails from "@rails/ujs"
// import * as ActiveStorage from "@rails/activestorage"
import * as bootstrap from "bootstrap"

Rails.start();
// ActiveStorage.start();

// (function() {
//   jQuery(function() {
//     $("a[rel=popover]").popover();
//     $(".tooltip").tooltip();
//     return jQuery("a[rel=tooltip]").tooltip();
//   });
// }).call(this);
